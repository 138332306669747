import React, { useState } from "react";
import "./App.scss";
import { Hero, Info, Tele, Footer, Minting } from "./components";

const App = () => {
  const [popup, setpopup] = useState(false);

  return (
    <div className="BG">
      <div className="app_container">
        <Hero />
        <Minting />
        <Tele />
        <Info />
        <Footer />
      </div>
    </div>
  );
};

export default App;
