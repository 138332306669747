import React, { useState, useRef, useEffect } from "react";
import "./Minting.scss";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { useTimer } from "react-timer-hook";
import toast, { Toaster } from "react-hot-toast";

import { getProofForAddress, contains } from "./Whitelist.js";

const Minting = () => {
  const Timer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp,
      //onExpire: () => console.warn("onExpire called"),
      autoStart: true,
    });
    return (
      <div className="timer_container">
        <div>
          <h1>{days > 9 ? days : `0${days}`}</h1>
          <h2>days</h2>
        </div>
        <div className="line" />
        <div>
          <h1>{hours > 9 ? hours : `0${hours}`}</h1>
          <h2>hrs</h2>
        </div>
        <div className="line" />
        <div>
          <h1>{minutes > 9 ? minutes : `0${minutes}`}</h1>
          <h2>mins</h2>
        </div>
        <div className="line" />
        <div>
          <h1>{seconds > 9 ? seconds : `0${seconds}`}</h1>
          <h2>sec</h2>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "true") {
        try {
          dispatch(connect());
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      getConfig();
      getData();
    }
  }, []);

  useEffect(() => {
    //getData();

    getConfig();
    getData();
    getMintEvent();
    getWhitelistEvent();
    getPauseEvent();
  }, [blockchain.account]);

  const WhiteListMint = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit + 10000 * mintAmount);
    let addressProf = getProofForAddress(blockchain.account);
    //console.log("Cost: ", totalCostWei);
    //console.log("Gas limit: ", totalGasLimit);
    //setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    const tx = blockchain.smartContract.methods
      .whitelistMint(mintAmount, addressProf)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        //setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        //console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    return tx;
  };

  const PublicMint = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit + 10000 * mintAmount);
    //console.log("Cost: ", totalCostWei);
    //console.log("Gas limit: ", totalGasLimit);
    //setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);

    setClaimingNft(true);
    const tx = blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");

        setClaimingNft(false);
      })
      .then((receipt) => {
        //console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    return tx;
  };

  const addressClaimed = (addr) => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .addressClaimed(addr)
        .call()
        .then((value) => {
          console.log(value);
          return value;
        });
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getMintEvent = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      await blockchain.smartContract.events
        .minted()
        .on("connected", function (subscriptionId) {
          //console.log(subscriptionId);
        })
        .on("data", function (event) {
          //console.log(event); // same results as the optional callback above
          getData();
        });
    }
  };

  const getWhitelistEvent = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      await blockchain.smartContract.events
        .whitelist()
        .on("connected", function (subscriptionId) {
          //console.log(subscriptionId);
        })
        .on("data", function (event) {
          //console.log(event); // same results as the optional callback above
          getData();
        });
    }
  };

  const getPauseEvent = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      await blockchain.smartContract.events
        .pause()
        .on("connected", function (subscriptionId) {
          //console.log(subscriptionId);
        })
        .on("data", function (event) {
          //console.log(event); // same results as the optional callback above
          getData();
        });
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (value <= 2 && value > 0) {
      setMintAmount(value);
    } else if (value <= 0) {
      setMintAmount(1);
    } else if (value > 2) {
      setMintAmount(2);
    } else {
      setMintAmount(value);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const loadingMarkup = (
    <div>
      <h3>Loading..</h3>
    </div>
  );
  /*
  data.paused = true;
  data.whitelistMintEnabled = true;
  data.totalSupply = 5000;
*/
  return (
    <div className="big_container">
      <Toaster />

      <div className="left">
        <div className="timer">
          <h1 className="white">Whitelist Mint</h1>
          <Timer expiryTimestamp={new Date(Date.UTC(2022, 5, 23, 14, 0, 0))} />
        </div>
        <div className="timer">
          <h1 className="white">Public Mint</h1>
          <Timer expiryTimestamp={new Date(Date.UTC(2022, 5, 24, 14, 0, 0))} />
        </div>
      </div>

      <div className="mint">
        {data.loading || claimingNft ? (
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : blockchain.account === "" || blockchain.account == null ? (
          <div className="connect_container">
            <div className="border">
              <p>Please connect your wallect</p>
            </div>
            <button
              //type="button"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
              }}
            >
              <p>Connect Wallet</p>
            </button>
          </div>
        ) : data.paused == true &&
          data.whitelistMintEnabled == false &&
          data.totalSupply <= 524 ? (
          <div className="connect_container">
            <div className="border">
              <p>The Whitelist Sale is not start yet</p>
              {contains(blockchain.account) ? (
                <div>
                  <p>Congratulations</p>
                  <p>You Are In Whitelist</p>
                </div>
              ) : (
                <p>You Are Not In Whitelist</p>
              )}
            </div>
          </div>
        ) : data.paused == true &&
          data.whitelistMintEnabled == false &&
          data.totalSupply > 524 ? (
          <div className="minting_container">
            <h1>
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </h1>
            <div className="border">
              <p>The Public Sale is not start yet</p>
            </div>
          </div>
        ) : (
          <div className="minting_container">
            <h1>
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </h1>
            {/*
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                className="increase"
                onClick={(e) => {
                  e.preventDefault();
                  decrementMintAmount();
                }}
              >
                -
              </button>
              <input
                className="p-text"
                type="text"
                placeholder="Amount"
                name="name"
                value={mintAmount}
                onChange={handleInputChange}
              ></input>
              <button
                className="increase"
                onClick={(e) => {
                  e.preventDefault();
                  incrementMintAmount();
                }}
              >
                +
              </button>
            </div>
            */}
            {/* <span>{data.paused ? 'The sale is not start yet' : 'Started'}</span> */}
            {/* <span>
            {data.whitelistMintEnabled ? 'Whitelist only' : 'Everyone can buy'}
          </span> */}

            {data.whitelistMintEnabled == true ? (
              <button
                className="mint_button"
                disabled={
                  !contains(blockchain.account) || data.whitelistAddressClaimed
                }
                onClick={(e) => {
                  e.preventDefault();
                  toast.promise(WhiteListMint(), {
                    loading: "Minting...",
                    success: <b>Sucessfully Minted!</b>,
                    error: <b>Something went wrong</b>,
                  });
                  //WhiteListMint();
                  getData();
                }}
              >
                {!contains(blockchain.account)
                  ? "You Are Not In Whitelist"
                  : data.whitelistAddressClaimed
                  ? "You already Claimed"
                  : "Mint"}
              </button>
            ) : (
              <button
                className="mint_button"
                disabled={data.totalSupply == 5000 || data.addressClaimed}
                onClick={(e) => {
                  e.preventDefault();
                  toast.promise(PublicMint(), {
                    loading: "Minting...",
                    success: <b>Sucessfully Minted!</b>,
                    error: <b>Something went wrong</b>,
                  });
                  //PublicMint();
                  getData();
                }}
              >
                {data.totalSupply == 5000
                  ? "The Sale was ended"
                  : data.addressClaimed
                  ? "You already Claimed"
                  : "Mint"}
              </button>
            )}
            {/* {feedback} */}
            {blockchain.errorMsg !== "" ? blockchain.errorMsg : null}
            <h1 className="price">
              FREE MINT !<p>Max Claim: 1 WeAre</p>
              {/*<p>One transaction per address ONLY</p>*/}
            </h1>
            {
              <a
                href="https://opensea.io/collection/wearebyjohnjohnny"
                target="_blank"
              >
                <button className="opensea_btn">View on OpenSea</button>
              </a>
            }
          </div>
        )}
        {/*blockchain.errorMsg !== "" ? blockchain.errorMsg : null*/}
      </div>
    </div>
  );
};

export default Minting;
