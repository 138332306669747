import React from "react";
import "./Footer.scss";
import footer_logo from "../../assets/LOGO2.png";

const Footer = () => {
  return (
    <div className="footer">
      <img src={footer_logo} alt="logo"></img>
      <a>
        Partner with{" "}
        <a href="https://app.traitsniper.com/wearebyjohnjohnny?
" target="_blank">
          trait sniper
        </a>
      </a>
      <p>All Rights Reserved to WeAre. 2022</p>
    </div>
  );
};

export default Footer;
