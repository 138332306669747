import React, { useState, useEffect, useRef, Component } from 'react'
import './Tele.scss'
import ReactPlayer from 'react-player'
import teleplay from '../../assets/tele.mp4'
import thumb from '../../assets/Thumb.png'
import { FiPlayCircle } from 'react-icons/fi'
import { FiPauseCircle } from 'react-icons/fi'

const Tele = () => {
  const playerRef = useRef(null)
  const [end, setEnd] = useState(false)
  const ending = () => {
    setEnd(!end)
  }
  const [play, setPlay] = useState(false)
  const ending2 = () => {
    setPlay(false)
  }
  /* useEffect(() => {
    if (playerRef) {
      playerRef.current.showPreview()
    }
  }, [end]) */
  return (
    <div className='video_container'>
      <div className='player_container'>
        <ReactPlayer
          ref={playerRef}
          url={teleplay}
          width='100%'
          height='100%'
          className='player'
          /* light={thumb} */
          playing={play}
          onEnded={ending2}
          playsinline={true}
        />
        {play ? (
          <FiPauseCircle
            size={120}
            className='controls controls_pause'
            onClick={() => {
              setPlay(false)
            }}
          />
        ) : (
          <FiPlayCircle
            size={120}
            className='controls'
            onClick={() => {
              setPlay(true)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default Tele
