import React from 'react'
import './Info.scss'
import background from '../../assets/background.png'
import logo from '../../assets/logo3.png'
import man from '../../assets/man.gif'
import { FiInstagram } from 'react-icons/fi'
import { FiTwitter } from 'react-icons/fi'
import { FaDiscord } from 'react-icons/fa'
import { FaBehance } from 'react-icons/fa'
import { useInView } from 'react-intersection-observer'

const Info = () => {
  const { ref, inView } = useInView({
    trackVisibility: true,

    delay: 101,

    threshold: 0,
  })
  return (
    <div className='info_container'>
      <img src={background} alt='background' className='background' />
      <div className='infos '>
        <div className='info'>
          <img
            ref={ref}
            src={man}
            alt='man'
            className={
              inView ? 'man animate__animated animate__jackInTheBox' : 'no_man'
            }
          />
          <p className={inView ? 'wording' : 'no_word'}>
            Award winning motion and illustration artist. Proud creator of
            WeAre.
          </p>
          <div
            className={
              inView
                ? 'social animate__animated animate__slideInLeft '
                : 'no_social'
            }
            ref={ref}
          >
            <a href='https://www.instagram.com/johnjohnnyart/' target='_blank'>
              <FiInstagram size={32} className='social_logo' />
            </a>
            <a href='https://twitter.com/JohnJohnnyArt' target='_blank'>
              <FiTwitter size={32} className='social_logo' />
            </a>
            <a href='https://www.behance.net/john-johnny' target='_blank'>
              <FaBehance size={32} className='social_logo' />
            </a>
          </div>
        </div>
        <div className='info'>
          <img
            ref={ref}
            src={logo}
            alt='logo'
            className={
              inView
                ? 'logo animate__animated animate__jackInTheBox'
                : 'no_logo'
            }
          />
          <p className={inView ? 'wording' : 'no_word'}>
            WeAre is a proud member of Pipl(people). The most dedicated NFT's
            philanthropic art collective.
          </p>
          <div
            className={
              inView
                ? 'social animate__animated animate__slideInRight '
                : 'no_social'
            }
            ref={ref}
          >
            <a href='https://www.instagram.com/piplworld2022/' target='_blank'>
              <FiInstagram size={32} className='social_logo' />
            </a>
            <a href='https://twitter.com/PiplWorld' target='_blank'>
              <FiTwitter size={32} className='social_logo' />
            </a>
            {/* <a href='http://discord.gg/pipl' target='_blank'>
              <FaDiscord size={32} className='social_logo' />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
