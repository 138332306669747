import React from "react";
import "./Hero.scss";
import banner from "../../assets/banner.gif";
import arrow from "../../assets/arrow.svg";
import { FiInstagram } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { FaDiscord } from "react-icons/fa";
import opensea from "../../assets/openseabutton.svg";
import "animate.css";

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="social_container animate__animated animate__bounceInLeft animate__fast">
        {/* <a href="https://www.instagram.com/weare.nft2022/" target="_blank">
          <FiInstagram size={32} className="social" />
        </a> */}
        <a href="https://twitter.com/weare_nft" target="_blank">
          <FiTwitter size={32} className="social" />
        </a>
        <a href="https://opensea.io/collection/wearebyjohnjohnny" target="_blank">
           <img className="opensea" src={opensea} alt='opensea' />
        </a>
        {/* <a href='http://discord.gg/pipl' target='_blank'>
          <FaDiscord size={32} className='social' />
        </a> */}
      </div>
      <img src={banner} alt="banner" />
      <img
        src={arrow}
        alt="arrow"
        className="animate__animated animate__slideOutDown  animate__infinite arrow"
      />
    </div>
  );
};

export default Hero;
